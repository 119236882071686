.coach-edit {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .delete {
    margin-right: 12px;
  }

  .title-profile {
    box-shadow: none;
    padding: 32px;

    &__avatar {
      text-transform: uppercase;
      background: var(--profile-avatar-background);
      color: var(--main-black);
      font-size: 32px;
      letter-spacing: -0.76px;
      font-weight: 900;
      border-radius: 50%;
      min-width: 64px;
      line-height: 64px;
      text-align: center;
      margin-right: 20px;
    }

    &__text {
      color: var(--main-black);
      font-size: 28px;
      letter-spacing: -0.67px;
      font-weight: 900;
      margin: 0;
    }

    md-checkbox {
      margin-right: 20px;
    }

    &.user-details-panel {
      margin-top: 0;
    }
  }

  .change-certificates {
    padding: 25px 25px 54px 25px;

    &-item {
      margin-right: 20px;
    }

    &__text {
      color: #7a7f93;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      margin-bottom: 5px;
      margin-left: 5px;
    }

    .empty {
      height: 132px;
      width: 204px;
      border: 2px dashed rgba(136, 140, 158, 0.36);
      background-color: rgba(75, 90, 120, 0.04);
      border-radius: 5px;
      margin-top: 30px;
      margin-right: 16px;

      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;

      md-icon {
        min-width: 49px;
        min-height: 55px;
        margin: 25px auto 15px;
      }
    }

    md-icon {
      min-width: 221px;
      min-height: 176px;
    }
  }
}
