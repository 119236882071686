.resultDateCell {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.gridRow {
  display: grid;
  grid-template-columns: 3fr 3fr repeat(3, 1fr);
  gap: 24px;
  align-items: start;
}
