/* stylelint-disable no-descending-specificity */

// use to add delete button (omit button in button)
.tabWrapper {
  position: relative;
}

// button style reset
.tab {
  width: 100%;
  min-width: auto;
  height: 100%;
  text-align: start;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullWidth {
  width: 100%;
}

.underline {
  display: flex;
  gap: 24px;

  .tab {
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0 0 12px;
    font: var(--body-14-bold);
    color: var(--natural-600);
    border-radius: 0;

    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: ' ';
      background-color: var(--main-black);
      transition: transform 0.2s ease-in-out;
      transform: scaleX(0);
    }

    &:hover {
      color: var(--main-black);
    }

    &:global(.base--selected) {
      color: var(--main-black);

      ::after {
        transform: scaleX(1);
      }
    }
  }
}

.toggle {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 8px;
  padding: 2px;
  margin-bottom: 16px;
  background-color: var(--background-50-dark);
  border-radius: 8px;

  &:global(.base-TabsList-horizontal) {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  &:global(.base-TabsList-vertical) {
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
  }

  .tab {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 40px; // fix for icon
    padding: 10px 24px;
    font: var(--body-14-bold);
    color: var(--natural-700);
    border-radius: 8px;
    transition: 0.2s ease-in-out;

    &:hover {
      color: var(--main-black);
    }

    &:global(.base--selected) {
      color: var(--main-black);
      background-color: var(--white);
      box-shadow: var(--tab-shadow);
    }
  }
}

.horizontal-page {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-bottom: 16px;

  .tab {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    font: var(--title-16-bold);
    color: var(--main-black);
    background-color: var(--white);
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: var(--panel-shadow);
    transition: 0.2s ease-in-out;

    .icon {
      width: 44px;
      height: 44px;
      background-color: var(--natural-100);
      border-radius: 50%;
    }

    &:hover,
    &:global(.base--selected) {
      border-color: var(--primary-500);

      .icon {
        color: var(--primary-500);
        background-color: var(--warning-50);
      }
    }
  }
}

.vertical-page {
  display: inline-grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  width: 292px;
  margin-right: 16px;
  overflow: hidden;
  background-color: var(--background-50-dark);
  border-radius: 8px;

  .tab {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: start;
    padding: 20px 28px;
    font: var(--body-14-bold);
    color: var(--natural-700);
    border-bottom: 1px solid var(--natural-300);
    border-radius: 0;

    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 4px;
      height: 100%;
      content: ' ';
      background-color: var(--main-black);
      transform: scaleY(0);
    }

    &:hover {
      color: var(--main-black);
    }

    &:global(.base--selected) {
      color: var(--main-black);
      background-color: var(--white);

      ::after {
        transform: scaleY(1);
      }
    }
  }

  :has(.deleteBtn) .tab {
    padding-right: 36px; // 16px padding + 20px delete button
  }

  .deleteBtn {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}
